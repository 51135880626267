<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลใบสั่งซื้อสินค้าเลขที่ {{ $route.params.id }}
      </v-toolbar-title>

      <v-spacer/>

      <v-btn
          v-if="$store.state.authUser.user_permissions.includes('delete_purchaseorder')"
          :disabled="['ยกเลิก', 'ยกเลิก (กดรับแล้ว)'].includes(statusPO)"
          @click="showConfirmDeleteDialog = true" depressed color="error"
          class="mx-0">
        <v-icon left>mdi-close</v-icon>
        ยกเลิกใบสั่งสินค้านี้
      </v-btn>
    </v-toolbar>
    <div class="mx-4 box box-2" style="min-height: 100%;">
      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">
        <v-col
            style="height: 100%"
        >
          <v-card
              style="height: 100%;overflow: auto"
              class="pa-4 rounded-lg elevation-2"
              outlined
              tile
          >

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col cols="3" align-self="center">หมายเลขที่</v-col>
                <v-col cols="5">
                  {{ poDetail.po.id }}
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">ผู้เปิด PO</v-col>
                <v-col cols="5">{{ created_by }}</v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">วันที่สั่ง</v-col>
                <v-col cols="5">
                  {{ poDetail.po.order_date }}
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">วันที่รับของ</v-col>
                <v-col cols="5">
                  {{ receive_date }}
                  <!--                  <DatePicker v-model="receive_date"-->
                  <!--                              placeholder="เลือกวันที่"-->
                  <!--                              :min="new Date().toISOString().substr(0, 10)"-->
                  <!--                              hide-details="auto"-->
                  <!--                              default-today-->
                  <!--                              :disabled="!editable"-->
                  <!--                  />-->

                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">วิธีรับของ</v-col>
                <v-col cols="5">
                  {{ receive_type }}
                  <!--                  <v-select-->
                  <!--                      outlined-->
                  <!--                      dense-->
                  <!--                      height="32px"-->
                  <!--                      class="rounded mt-3"-->
                  <!--                      v-model="receive_type"-->
                  <!--                      :items="receive_type_select"-->
                  <!--                      hide-details="auto"-->
                  <!--                      placeholder="เลือกวิธีรับสินค้า"-->
                  <!--                      :rules="[rules.required_select]"-->
                  <!--                      :disabled="!editable"-->
                  <!--                  ></v-select>-->
                  <!--                <v-text-field :disabled="!editable" outlined height="32px" hide-details/>-->
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">สถานที่</v-col>
                <v-col cols="5">
                  {{ supplierName }}
                  <!--                  <v-select v-model="supplier"-->
                  <!--                            :items="supplierSelect"-->
                  <!--                            outlined-->
                  <!--                            dense-->
                  <!--                            height="32px"-->
                  <!--                            hide-details="auto"-->
                  <!--                            placeholder="เลือกสถานที่สั่ง"-->
                  <!--                            :rules="[rules.required_select]"-->
                  <!--                            item-text="name"-->
                  <!--                            item-value="id"-->
                  <!--                            :disabled="!editable"-->
                  <!--                  ></v-select>-->
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3" align-self="center">สถานะ</v-col>
                <v-col cols="3" align-self="center"
                       :class="{'red--text':statusPO==='รอรับสินค้า','green--text':statusPO==='รับสินค้าแล้ว'}">
                  {{ statusPO }}
                </v-col>
              </v-row>


              <!--              <v-row v-else class="mt-6 mb-6">-->
              <!--                <v-btn depressed @click="editable = false;transformToTable(tmpEdit)" color="cancel" class="mx-4">-->
              <!--                  ยกเลิก-->
              <!--                </v-btn>-->
              <!--                <v-btn depressed @click="saveEditPO" color="success" class="mr-4">บันทึกการแก้ไข</v-btn>-->
              <!--              </v-row>-->

              <v-row class="mt-4">
                <v-col align-self="center">
                  <span
                      class="mr-2">สินค้าที่สั่ง ( {{
                      po_item.length
                    }} {{ this.receive_type === 'ออกไปรับ' ? 'เที่ยว' : 'รายการ' }} )</span>
                  <!--                  <v-icon-->
                  <!--                      v-if="statusPO === 'รอรับสินค้า'"-->
                  <!--                      @click="showProductFormDialog = true;isForCreateProduct = true;formProductSelect=null;formProductQuantity = 0;formProductPrice = 0"-->
                  <!--                      color="success">-->
                  <!--                    mdi-plus-circle-->
                  <!--                  </v-icon>-->
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  <v-data-table
                      :headers="productSelectHeader"
                      :items="po_item"
                      disable-sort
                      hide-default-footer
                  >
                    <template #item.no="{item}">
                      <!--                      <td>-->
                      <!--                        {{ item.runNum }}-->
                      {{ po_item.indexOf(item) + 1 }}
                      <!--                      </td>-->
                    </template>
                    <template #item.round="{ item }">
                      {{ item.round }}
                    </template>
                    <!--                    <template #item.price="{ item }">-->
                    <!--                      {{ item.price }} บาท-->
                    <!--                    </template>-->
                    <template #item.driver="{ item }">
                      {{ item.driver.first_name }} {{ item.driver.last_name }}
                    </template>
                    <template #item.vehicle="{ item }">
                      {{ item.vehicle.name }}
                      <span class="blue--text">ทะเบียน {{ item.vehicle.licence }}</span>
                    </template>
                    <template v-if="statusPO==='รับสินค้าแล้ว'" slot="body.append">
                      <tr class="primary--text">
                        <td class="font-size-5 font-bold text-left">รวม</td>
                        <td class="text-center font-size-5 font-bold">{{ $currency(poDetail.po.total_price).format() }}
                          บาท
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>

              <v-row v-if="!editable" class="mt-6 mb-6">
                <v-btn @click="goPrint()"
                       :disabled="statusPO==='รับสินค้าแล้ว'" depressed color="primary" class="mx-4">
                  <v-icon left>mdi-printer</v-icon>
                  ปริ้นใบสั่งสินค้า
                </v-btn>
                <v-btn
                    v-if="$store.state.authUser.user_permissions.includes('change_purchaseorder')"
                    :disabled="statusPO !== 'รอรับสินค้า'" depressed @click="pickUpDialog = true" width="122"
                    color="success" class="mr-4">
                  <v-icon left>mdi-package-down</v-icon>
                  รับสินค้า
                </v-btn>
              </v-row>


              <v-dialog
                  v-model="pickUpDialog"
                  width="700"
                  persistent
              >

                <v-stepper v-model="e1">
                  <v-stepper-items>
                    <v-stepper-content v-for="(item,index) in po_item" :key="item.id" :step='index+1'>
                      <PickinPage :item="item"
                                  :product-index="e1"
                                  :length-product="po_item.length"
                                  @next="e1++"
                                  @prev="e1--"
                                  @close="pickUpDialog = false"
                                  @close_and_refresh="pickUpDialog = false;getPODetail()"
                      />
                    </v-stepper-content>
                    <v-stepper-content :step='po_item.length+1'>
                      <v-card
                          class="mb-4"
                      >
                        <v-card-title class="px-0 pt-0 mb-4 d-flex justify-space-between" style="font-size: 1.5rem">
                          <span>ใส่ราคารับของ</span>
                          <v-icon @click="pickUpDialog = false">mdi-close</v-icon>
                        </v-card-title>
                      </v-card>
                      <v-text-field v-model="total_price" hint="ราคาที่กรอกจะนำไปรวมเป็นรายจ่าย"
                                    :rules="[rules.min,rules.required]"
                                    label="ราคารับของ" outlined
                                    dense></v-text-field>

                      <div class="d-flex justify-end">
                        <v-btn
                            :disabled="e1 === 1"
                            text
                            @click="e1--"
                        >
                          ย้อนกลับ
                        </v-btn>
                        <v-btn
                            width="150"
                            color="success"
                            @click="pickInAllProduct()"
                        >
                          นำเข้าสินค้า
                        </v-btn>


                      </div>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-dialog>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </div>


    <!--    delete confirm dialog-->
    <v-dialog
        v-model="showConfirmDeleteDialog"
        persistent
        max-width="528"
    >
      <template v-slot:activator="{ on, attrs }">
      </template>
      <v-card>
        <v-card-title>
          <p>คุณแน่ใจที่จะยกเลิก PO นี้
          </p>
        </v-card-title>
        <v-card-text>
          <h3 class="red--text">การกระทำดังกล่าวไม่สามารถย้อนกลับได้</h3>
          <template v-if="statusPO === 'รับสินค้าแล้ว'">
            <v-divider class="my-3"/>
            <h3 class="red--text">PO นี้ได้มีการกดรับสินค้าแล้ว การยกเลิกจะทำการย้อนคืน Stock สินค้า</h3>
            <v-list>
              <v-list-item v-for="stock_location in poDetail.stock_location_transactions" :key="stock_location.id">
                - {{ stock_location.stock_location_name }} คืนจำนวน  <b class="red--text ml-2">{{ stock_location.amount }} {{ stock_location.po_item.unit }}</b>
              </v-list-item>
            </v-list>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn
              text
              @click="showConfirmDeleteDialog = false;"
          >
            ยกเลิก
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              color="error"
              @click="cancelPO()"
          >
            <v-icon left>mdi-delete</v-icon>
            ยืนยันการลบ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import DatePicker from "@/components/DatePicker";
import PickinPage from "@/components/PickinPage";

export default {
  name: "CreatePO",
  components: {DatePicker, PickinPage},
  data() {
    return {
      e1: 1,
      poDetail: {
        po: {
          id: 0,
          order_date: ''
        }
      },
      tmpEdit: null,
      productSelectHeader: [],
      // tripHeader: [
      //   {
      //     text: 'ลำดับที่',
      //     value: 'no',
      //     align: 'left',
      //     class: "blue lighten-5",
      //     width: 80
      //   },
      //   {
      //     text: 'คนขับรถ',
      //     value: 'driver_id',
      //     align: 'center',
      //     class: "blue lighten-5"
      //     // width: 268
      //   },
      //   {
      //     text: 'รถ',
      //     value: 'vehicle_id',
      //     align: 'center',
      //     width: 268,
      //     class: "blue lighten-5"
      //   },
      //   {
      //     text: '',
      //     value: 'actions',
      //     align: 'center',
      //     width: 30,
      //     class: "blue lighten-5"
      //   },
      // ],
      order_date: '',
      receive_date: '',
      receive_type: '',
      supplier: '',
      supplierSelect: [],
      trips: [],
      driverSelect: [],
      vehicleSelect: [],
      products: [],
      productsSelect: [],
      receive_type_select: ['ออกไปรับ', 'มาส่ง'],
      statusPO: false,
      rules: {
        required: value => !!value || "ต้องกรอก",
        required_select: value => !!value || "ต้องเลือก",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 0 ขึ้นไป"
      },
      valid: true,
      editable: false,
      pickUpDialog: false,
      productStockData: [],
      showProductFormDialog: false,
      showTripFormDialog: false,
      isForCreateProduct: false,
      isForCreateTrip: false,
      validProductEdit: false,
      validTripEdit: false,
      formProductSelect: 0,
      formProductQuantity: 0,
      formProductPrice: 0,
      formTripDriver: 0,
      formTripVehicle: 0,
      productSelected: {},
      tripSelected: {},
      showConfirmDeleteDialog: false,
      isDeleteProduct: false,
      po_item: [],
      created_by: '',
      supplierName: '',
      total_price: ''
    }
  },
  async created() {
    this.$store.state.pickInData = []
    this.$store.state.isLoading = false
    await this.getProductsSelect()
    // await this.getSupplierSelect()
    // await this.getDriver()
    // await this.getVehicle()
    // await Promise.all([this.getProductsSelect(), this.getSupplierSelect(), this.getDriver(), this.getVehicle()])
    // setTimeout(async () => {
    //   await this.getPODetail()
    // }, 2000)
  },
  methods: {
    goPrint() {
      let route = this.$router.resolve({path: '/print', query: {name: 'po', po_id: this.$route.params.id}})
      window.open(route.href, '_blank');
    },
    sumField() {
      return this.po_item.reduce((a, b) => a + (parseFloat(b['price']) || 0), 0)
    },
    getProductsSelect() {
      axios.get('/api/stocks/product/')
          .then(async res => {
            let productList = []
            await res.data.data.forEach(p => {
              productList.push({
                product_id: p.id,
                unit: p.unit,
                name: p.name,
              })
            })
            this.productsSelect = productList
            this.productStockData = res.data.data
            await this.getPODetail()
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
          .finally()
    },
    // getSupplierSelect() {
    //   axios.get('/api/stocks/supplier/')
    //       .then(res => {
    //         this.supplierSelect = res.data.data
    //       })
    //       .catch(e => this.$store.state.snackbar = {
    //         text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //         visible: true,
    //         color: 'error'
    //       })
    // },
    // getDriver() {
    //   axios.get('/api/stocks/driver/')
    //       .then(res => {
    //         this.driverSelect = res.data.data
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       })
    // },
    // getVehicle() {
    //   axios.get('/api/stocks/vehicle/รถบรรทุก/')
    //       .then(res => {
    //         this.vehicleSelect = res.data.data
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       })
    // },
    getPODetail() {
      this.$store.state.isLoading = true
      axios.get(`/api/stocks/po/${this.$route.params.id}/`)
          .then(res => {
            this.transformToTable(res.data.data)
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          }).finally(() => this.$store.state.isLoading = false)
    },
    async transformToTable(po) {
      this.poDetail = po
      this.supplier = this.poDetail.po.supplier.id
      this.receive_date = this.poDetail.po.receive_date
      this.receive_type = this.poDetail.po.receive_type
      this.statusPO = this.poDetail.po.status
      this.supplierName = this.poDetail.po.supplier.name
      this.created_by = this.poDetail.po.created_by.first_name + " " + this.poDetail.po.created_by.last_name

      this.po_item = []

      if (this.receive_type === 'ออกไปรับ') {
        this.productSelectHeader = [
          {
            text: 'ลำดับที่',
            value: 'no',
            align: 'left',
            class: "blue lighten-5",
            width: 130,
          },
          {
            text: 'สินค้า',
            value: 'name',
            align: 'left',
            class: "blue lighten-5"
          },
          {
            text: 'เที่ยว',
            value: 'round',
            align: 'left',
            class: "blue lighten-5",
            width: 130,
          },
          {
            text: 'พนักงานขับรถ',
            value: 'driver',
            align: 'center',
            width: 256,
            class: "blue lighten-5"
          },
          {
            text: 'รถ',
            value: 'vehicle',
            align: 'center',
            width: 256,
            class: "blue lighten-5"
          },
        ]
        this.poDetail.trip.forEach((p, index) => {
          let item = p.po_item
          item["driver"] = p.driver
          item['vehicle'] = p.vehicle
          item['product'] = this.productStockData.filter(s => s.id === p.po_item.product)[0]
          item['round'] = p.round
          item['runNum'] = index + 1
          this.po_item.push(item)
        })
      } else {
        this.productSelectHeader = [
          {
            text: 'ลำดับที่',
            value: 'no',
            align: 'left',
            class: "blue lighten-5",
            width: 130,
          },
          {
            text: 'สินค้า',
            value: 'name',
            align: 'left',
            class: "blue lighten-5"
          },
        ]
        this.po_item = this.poDetail.po_item
        this.po_item.forEach((p, index) => {
          p.runNum = index + 1
          p.product["stock_location"] = this.productStockData.filter(s => s.id === p.product.id)[0].stock_location
        })
      }
    },
    // saveEditPO() {
    //   let payload = {
    //     id: parseInt(this.$route.params.id),
    //     receive_date: this.receive_date,
    //     supplier: this.supplier,
    //     receive_type: this.receive_type
    //   }
    //   axios.put('/api/stocks/po/', payload)
    //       .then(() => {
    //         this.editable = false
    //         this.$store.state.snackbar = {
    //           text: `บันทึกการแก้ไขข้อมูลของ PO แล้ว`,
    //           visible: true,
    //           color: 'success'
    //         }
    //       }).catch(e => {
    //     this.$store.state.snackbar = {
    //       text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //       visible: true,
    //       color: 'error'
    //     }
    //   })
    // },
    // addNewProduct() {
    //   let payload = {
    //     po_id: parseInt(this.$route.params.id),
    //     product_id: this.formProductSelect,
    //     quantity: parseFloat(this.formProductQuantity),
    //     price: parseFloat(this.formProductPrice)
    //   }
    //   axios.post('/api/stocks/po_item/', payload)
    //       .then(() => {
    //         this.getPODetail()
    //         this.showProductFormDialog = false
    //         this.$store.state.snackbar = {
    //           text: `เพื่มสินค้าลงใน PO นี้แล้ว`,
    //           visible: true,
    //           color: 'success'
    //         }
    //       }).catch(e => {
    //     this.$store.state.snackbar = {
    //       text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //       visible: true,
    //       color: 'error'
    //     }
    //   })
    // },
    // saveEditProduct() {
    //   let payload = {
    //     id: this.productSelected.po_item_id,
    //     quantity: parseFloat(this.formProductQuantity),
    //     price: parseFloat(this.formProductPrice)
    //   }
    //   axios.put('/api/stocks/po_item/', payload)
    //       .then(() => {
    //         this.getPODetail()
    //         this.showProductFormDialog = false
    //         this.$store.state.snackbar = {
    //           text: `แก้ไขข้อมูลสินค้าใน PO นี้แล้ว`,
    //           visible: true,
    //           color: 'success'
    //         }
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       })
    // },
    // deleteProduct() {
    //   axios.delete(`/api/stocks/po_item/${this.productSelected.po_item_id}/`)
    //       .then(res => {
    //         this.showConfirmDeleteDialog = false
    //         this.getPODetail()
    //         this.$store.state.snackbar = {
    //           text: `ลบสินค้าใน PO นี้แล้ว`,
    //           visible: true,
    //           color: 'success'
    //         }
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       })
    // },
    // addNewTrip() {
    //   let payload = {
    //     po_id: this.$route.params.id,
    //     driver_id: this.formTripDriver,
    //     vehicle_id: this.formTripVehicle
    //   }
    //   axios.post('/api/stocks/trip/', payload)
    //       .then(() => {
    //         this.getPODetail()
    //         this.showTripFormDialog = false
    //         this.$store.state.snackbar = {
    //           text: `เพิ่มเที่ยวรถใน PO นี้แล้ว`,
    //           visible: true,
    //           color: 'success'
    //         }
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       })
    // },
    // deleteTrip() {
    //   // console.log(this.tripSelected)
    //   axios.delete(`/api/stocks/trip/${this.tripSelected.id}/`)
    //       .then(() => {
    //         this.getPODetail()
    //         this.showConfirmDeleteDialog = false
    //         this.$store.state.snackbar = {
    //           text: `ลบเที่ยวรถใน PO นี้แล้ว`,
    //           visible: true,
    //           color: 'success'
    //         }
    //       })
    //       .catch(e => {
    //         this.$store.state.snackbar = {
    //           text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
    //           visible: true,
    //           color: 'error'
    //         }
    //       })
    // },
    cancelPO() {
      axios.delete(`/api/stocks/po/${this.$route.params.id}/`).then(() => {
        this.$store.state.snackbar = {
          text: `ลบ PO สำเร็จแล้ว`,
          visible: true,
          color: 'success'
        }
        this.$router.push({path: '/stock/po'})
      }).catch(e => {
        console.error(e)
      })
    },
    pickInAllProduct() {
      this.$store.state.isLoading = true
      axios.post('/api/stocks/add2stock/', {
        data: this.$store.state.pickInData,
        total_price: parseFloat(this.total_price) // add total price
      })
          .then(() => {
            this.$store.state.snackbar = {
              text: `เพื่มสินค้าลงคลังสินค้าแล้ว`,
              visible: true,
              color: 'success'
            }
            this.pickUpDialog = false
            this.getPODetail()
            this.$emit('close_and_refresh')
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.$store.state.isLoading = false
      })
    }

  },
}
</script>

<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: transparent !important;
}

/*[class$="-disabled"],*/
/*[class*="-disabled "] * {*/
/*  color: #000 !important;*/
/*  border-color: #e5e5e5 !important;*/
/*}*/

</style>