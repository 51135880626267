<template>
  <div>
    <v-card
      class="mb-4"
      height="280"
    >
      <v-card-title class="px-0 pt-0 mb-4 d-flex justify-space-between" style="font-size: 1.5rem">
        <span>{{ item.runNum }}. เลือก Stock ของ <span
          class="orange--text mx-2">{{ item.product.name }}</span>
        </span>
        <v-icon @click="closeModal">mdi-close</v-icon>
      </v-card-title>
      <v-row v-if="item.product.isTrip">
        <v-col cols="2">
          หน่วยที่รับ
        </v-col>
        <v-col>
          <v-radio-group class="mt-0" v-model="fromTon" hide-details row>
            <v-radio label="ตัน"
                     :value="true"></v-radio>
            <v-radio :label="product_unit"
                     :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row v-for="(stock,index) in stocks" :key="stock.id">
        <v-col>{{ stock.location_name }} <span class="blue--text ml-2">(เหลือ {{
            stock.amount
          }})</span></v-col>
        <v-col :cols="fromTon ? 4: 6">
          <v-text-field
            :rules="[rules.min]"
            v-model="stock.pickin"
            min=0
            outlined
            hide-details
            :suffix="fromTon ? 'ตัน' : product_unit"
            type="number">
          </v-text-field>
        </v-col>
        <v-col cols="2" class="text-right" v-if="fromTon">
          ~ {{ calDensity(stock.pickin) }} {{ product_unit }}
        </v-col>
      </v-row>
    </v-card>
    <div class="d-flex justify-end">
      <v-btn
        :disabled="productIndex === 0"
        text
        @click="previousProduct"
      >
        ย้อนกลับ
      </v-btn>
      <v-btn
        v-if="productIndex === lengthProduct"
        width="150"
        color="success"
        @click="pickInAllProduct"
      >
        กรอกราคา
      </v-btn>
      <v-btn
        v-else
        width="150"
        color="primary"
        @click="nextProduct"
      >
        ต่อไป
      </v-btn>
    
    
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ["item", "productIndex", 'lengthProduct'],
  name: "PickInPage",
  data() {
    return {
      // productIndex:1,
      fromTon: false,
      items: [],
      product_unit: '',
      density: 1,
      stocks: [],
      rules: {
        required: value => !!value || "ต้องกรอก",
        required_select: value => !!value || "ต้องเลือก",
        min: value => value >= 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป"
      },
    }
  },
  created() {
    this.fromTon = this.item.product.isTrip
    this.product_unit = this.item.product.unit
    this.density = this.item.product.density || 1
    
  },
  mounted() {
    this.item.product.stock_location.forEach(s => {
      this.stocks.push({
        stock_location_id: s.stock_location_id,
        location_id: s.location_id,
        location_name: s.location_name,
        amount: s.amount,
        pickin: 0
      })
    })
  },
  methods: {
    pickIn() {
      this.stocks.forEach((s) => {
        let amount = this.fromTon ? parseFloat(this.calDensity(s.pickin)) : parseFloat(s.pickin)
        if (amount) {
          this.$store.commit('addPickInData', {
            po_item_id: this.item.id,
            stock_location_id: s.stock_location_id,
            amount: amount
          })
        }
      })
    },
    calDensity(value) {
      return (value / this.density).toFixed(2)
    },
    closeModal() {
      this.$emit('close')
    },
    
    nextProduct() {
      // this.productIndex++
      this.$emit('next')
      this.pickIn()
    },
    previousProduct() {
      if (this.productIndex > 1) {
        // this.productIndex--
        this.$emit('prev')
      }
    },
    pickInAllProduct() {
      this.pickIn()
      this.$emit('next')
    }
  },
  watch: {
    item(value) {
      this.items = value
    }
  }
}
</script>

<style scoped>

</style>